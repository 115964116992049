import React, { useEffect, useState } from 'react';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import request from 'superagent';

import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import Spinner from 'react-bootstrap/Spinner';
import CardColumns from 'react-bootstrap/CardColumns';

export default () => {
  const token = localStorage.getItem('token');
  if (!token) {
    window.location.replace(`https://slack.com/oauth/v2/authorize?scope=users.profile:read,users:read,team:read&client_id=${process.env.REACT_APP_SLACK_CLIENTID}&redirect_uri=${process.env.REACT_APP_API}/slack/authSlack`);
    return;
  }

  const [loading, setLoading] = useState(false);
  const [polls, setPolls] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    request
      .get(process.env.REACT_APP_API + '/polls')
      .set('token', token)
      .then(data => {
        setPolls(data.body.response);
        setLoading(false);
      })
      .catch(err => {
        setError(err.message);
        setLoading(false);
      });
  }, [token]);

  function showContent() {
    if (!polls) { return }
    if (!polls.length) {
      return <h1>You have not created any polls...</h1>;
    }
    return <div className='pollsContainer'>
      <CardColumns>
        {
          polls.map(poll => {
            return (
              <Card className='poll'>
                <Card.Body>
                  <Card.Title>{poll.topic}</Card.Title>
                  <Card.Text>
                    {
                      poll.options.sort((a, b) => b.voters.length - a.voters.length).map((option, i) => <li><span className='counter'>{i + 1}</span>. {option.option} <Badge className='optionVoters'>{option.voters.length} vote{option.voters.length !== 1 ? 's' : ''}</Badge></li>)
                    }
                  </Card.Text>
                </Card.Body>
                <div className='pollTags'>
                  {
                    poll.anonymousAuthor
                      ? <Badge variant='secondary' className='optionMeta'>Anonymous Author</Badge>
                      : null
                  }
                  {
                    poll.anonymousVoter
                      ? <Badge variant='secondary' className='optionMeta'>Anonymous Voter</Badge>
                      : null
                  }
                  <Badge variant='secondary' className='optionMeta'>Max votes per user: {poll.maxVotesPerUser}</Badge>
                </div>
              </Card>
            );
          })
        }
      </CardColumns>
    </div>
  }

  return (
    <div>
      <div className='banner'>
        <Navbar className='navBar' collapseOnSelect expand='lg' variant='dark'>
          <Navbar.Brand href='/'>Poll Bot</Navbar.Brand>
          <Navbar.Toggle aria-controls='responsive-navbar-nav' />
          <Navbar.Collapse id='responsive-navbar-nav'>
            <Nav className='mr-auto' />
            <Nav>
              <Nav.Link className='navBarItems border' href='/billing'>Billing</Nav.Link>
              <Nav.Link className='navBarItems' onClick={() => {
                localStorage.removeItem('token');
                window.location.replace('/');
              }}>Logout</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <div className='pollsBody'>
          {
            loading
              ?
              <Spinner style={{ color: 'white' }} animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
              : null
          }
          {showContent()}
        </div>
      </div>
    </div>
  );
}

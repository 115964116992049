import React from 'react';

import { Switch, Route } from 'react-router-dom';

import Home from './pages/Home.js';
import Dashboard from './pages/Dashboard.js';
import Billing from './pages/Billing.js';
import Policy from './pages/Policy.js';

function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path='/' component={Home} />
        <Route path='/dashboard' component={Dashboard} />
        <Route path='/billing' component={Billing} />
        <Route path='/policy' component={Policy} />
        <Route component={Home}/>
      </Switch>
    </div>
  );
}

export default App;

import React from 'react'

export default () => (
  <div className='policy'>
    <h1>Terms of Service</h1>
    <p>
      By using Super Poll, you accept our privacy policy. This service is not to be misused for causes such as targetted harrassment, bullying, etc. As per typical SAAS fashion,
      payment enables the usage of our application, however refunds will not be given for not using the application for any given month. You pay for the ability to use our services.
    </p>

    <h1>Information that is gathered from visitors</h1>
    <p>
      In common with other websites, log files are stored on the web server saving details such as the visitor's IP address, browser type, referring page and time of visit.
      Cookies may be used to remember visitor preferences when interacting with the website.
      Where slack authentication is required, only the name and ID of the workspace and users are saved. No messages or other server information is stored except for poll data.
          </p>

    <h1>How the Information is used</h1>
    <p>
      The information is used to enhance the vistor's experience when using the website to display personalised content and possibly advertising.
      E-mail addresses will not be sold, rented or leased to 3rd parties.
      E-mail may be sent to inform you of news of our services or offers by us or our affiliates.
          </p>

    <h1>Visitor Options</h1>
    <p>
      If you have subscribed to one of our services, you may unsubscribe by going to the billing page and cancelling your plan. Plan changes are only active on the next billing cycle.
      You may be able to block cookies via your browser settings but this may prevent you from access to certain features of the website.
    </p>

    <h1>Cookies</h1>
    <p>
      Cookies are small digital signature files that are stored by your web browser that allow your preferences to be recorded when visiting the website. Also they may be used to track your return visits to the website.
      3rd party advertising companies may also use cookies for tracking purposes.
    </p>
  </div>
);
import React, { useEffect, useState } from 'react';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Modal from 'react-bootstrap/Modal';

import { Helmet } from 'react-helmet';

import request from 'superagent';

import Pricing from '../components/Pricing.js';

export default () => {
  const token = localStorage.getItem('token');

  if (!token) {
    window.location.replace(process.env.REACT_APP_SLACK_INSTALL_LINK);
    return;
  }

  const [loading, setLoading] = useState(false);
  const [workspace, setWorkspace] = useState(null);
  const [error, setError] = useState(null);
  const [stripe, setStripe] = useState(null);
  const [show, setShow] = useState(false);
  const [genericMessage, setGenericMessage] = useState(null);
  const [showGenericMessage, setShowGenericMessage] = useState(false);
  const [changeBillingDetailsLoading, setChangeBillingDetailsLoading] = useState(false);
  const [cancelSubscriptionLoading, setCancelSubscriptionLoading] = useState(false);

  useEffect(() => {
    if (window.Stripe) {
      setStripe(window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY));
    }
  }, []);

  useEffect(() => {
    if (!genericMessage) { return; }
    setShowGenericMessage(true);
  }, [genericMessage]);

  useEffect(() => {
    setLoading(true);
    request
      .get(process.env.REACT_APP_API + '/workspace')
      .set('token', token)
      .query({ includePaymentMethod: true })
      .then(data => {
        if (!data.body.success) {
          throw new Error(data.body.response);
        }
        if (!data.body.response) {
          throw new Error('Sorry there, the dashboard can only be used if the app is installed properly. Please install it or reinstall it.');
        }
        setWorkspace(data.body.response);
        setLoading(false);
      })
      .catch(err => {
        setError(err.message);
        setLoading(false);
      })
  }, [token]);

  function updatePaymentMethod() {
    setChangeBillingDetailsLoading(true)
    request
      .post(process.env.REACT_APP_API + '/payment/update-payment')
      .set('token', token)
      .then(data => {
        setChangeBillingDetailsLoading(false)
        if (data.body.success) {
          stripe.redirectToCheckout({
            sessionId: data.body.sessionId
          });
        }
      })
      .catch(err => {
        setChangeBillingDetailsLoading(false)
        setError(err.message);
      });
  }

  function cancelPlan() {
    setCancelSubscriptionLoading(true);
    request
      .post(process.env.REACT_APP_API + '/plans/cancel-plan')
      .set('token', token)
      .then(data => {
        setCancelSubscriptionLoading(false);
        if (data.body.success) {
          setShow(false);
          setWorkspace(data.body.response);
          setGenericMessage('Success! You will not be charged again after this billing cycle.');
        }
      })
      .catch(err => {
        setCancelSubscriptionLoading(false);
        setError(err.message);
      });
  }

  function handleClose() { setShow(false); }
  function handleOpen() { setShow(true); }
  function handleCloseGenericMessage() { setShowGenericMessage(false); }

  return (
    <div>
      <Helmet>
        <meta name="theme-color" content="#ae21f0" />
      </Helmet>
      <Modal show={showGenericMessage} onHide={handleCloseGenericMessage}>
        <Modal.Body>{genericMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant='light' onClick={handleCloseGenericMessage}>
            OK
      </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Cancelling your subscription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to cancel your subscription?</p>
          <p>If you cancel now, your plan will be active until the end of your current and will no longer renew.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='light' onClick={handleClose}>
            Cancel
      </Button>
          <Button variant='primary' onClick={cancelPlan} disabled={cancelSubscriptionLoading}>
            {cancelSubscriptionLoading ? 'Cancelling...' : 'Continue'}
          </Button>
        </Modal.Footer>
      </Modal>
      <div className='billing'>
        <Navbar className='navBar' collapseOnSelect expand='lg' variant='dark'>
          <Navbar.Brand href='/'>Poll Bot</Navbar.Brand>
          <Navbar.Toggle aria-controls='responsive-navbar-nav' />
          <Navbar.Collapse id='responsive-navbar-nav'>
            <Nav className='mr-auto' />
            <Nav>
              <Nav.Link href='/dashboard' className='navBarItems border'>Dashboard</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <div className='billingBody'>
          {
            error
              ? <h1>{error}</h1>
              : null
          }
          {
            loading
              ?
              <Spinner style={{ color: 'white' }} animation='border' role='status'>
                <span className='sr-only'>Loading...</span>
              </Spinner>
              : null
          }
          {
            workspace
              ?
              <div>
                {
                  workspace.subscription !== 'none'
                    ? <p className='ph1'>Your workspace is currently on the <b>{workspace.subscription}</b> plan.</p>
                    : <p className='ph1'>Your workspace is currently not on a plan.</p>
                }
                {
                  workspace.subscription === 'trial'
                    ? <p className='ph3' style={{ marginTop: '-3vh' }}>Start a poll with <b>/superpoll</b> in any channel!</p>
                    : null
                }
                <Pricing
                  currentPlan={workspace.subscription}
                  workspaceSubscriptionId={workspace.stripe_subscription_id}
                  authedView={token}
                  updateWorkspace={setWorkspace} />
                {
                  workspace.stripe_subscription_id
                    ?
                    <div className='paymentSettings'>
                      <p className='ph2'>Account Settings</p>
                      <Button
                        variant='primary'
                        onClick={updatePaymentMethod} disabled={changeBillingDetailsLoading}>
                        {changeBillingDetailsLoading ? 'Loading...' : 'Change Billing Details'}
                      </Button>
                      <Button
                        variant='danger'
                        onClick={handleOpen}>
                        Cancel Subscription
                      </Button>
                    </div>
                    : null
                }
              </div>
              : null
          }
        </div>
      </div>
    </div >
  );
}

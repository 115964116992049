import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';

import request from 'superagent'

export default props => {
  const token = localStorage.getItem('token');
  const [stripe, setStripe] = useState(null);
  const [show, setShow] = useState(false);
  const [showGenericMessage, setShowGenericMessage] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [genericMessage, setGenericMessage] = useState(null);
  const [changePlanLoading, setChangePlanLoading] = useState(false);

  useEffect(() => {
    if (window.Stripe) {
      setStripe(window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY));
    }
  }, []);

  function choosePlan() {
    setChangePlanLoading(true);

    if (props.workspaceSubscriptionId) {
      request
        .post(process.env.REACT_APP_API + '/plans/change-plan')
        .set('token', token)
        .send({ plan: selectedPlan })
        .then(data => {
          if (!data.body.success) { throw data.body.response; }
          props.updateWorkspace(data.body.response);
          setShow(false);
          setGenericMessage('Success! Your plan will change after this billing cycle.');
          setShowGenericMessage(true);
          setChangePlanLoading(false);
        })
        .catch(err => {
          setChangePlanLoading(false);
          console.error(err);
        });
      return;
    }

    request
      .post(process.env.REACT_APP_API + '/payment/create-checkout-session')
      .set('token', token)
      .send({ plan: selectedPlan })
      .then(data => {
        setChangePlanLoading(false);
        if (data.body.success) {
          stripe.redirectToCheckout({
            sessionId: data.body.sessionId
          });
        }
      })
      .catch(err => {
        setChangePlanLoading(false);
        console.error(err);
      });
  }

  function changePlanConfirmation(plan) {
    if (!token || !props.authedView) {
      window.location.href = '/billing';
      return;
    }
    setSelectedPlan(plan);
    setShow(true);
  }

  function handleClose() { setShow(false); }
  function handleCloseGenericMessage() { setShowGenericMessage(false); }

  return [
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Changing a plan</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to change from <b>{props.currentPlan}</b> to <b>{selectedPlan}</b>? Your new plan will be active immediately.</Modal.Body>
      <Modal.Footer>
        <Button variant='light' onClick={handleClose}>
          Cancel
        </Button>
        <Button variant='primary' onClick={choosePlan} disabled={changePlanLoading}>
          {changePlanLoading ? 'Changing Plan...' : 'Continue'}
        </Button>
      </Modal.Footer>
    </Modal>,
    <Modal show={showGenericMessage} onHide={handleCloseGenericMessage}>
      <Modal.Body>{genericMessage}</Modal.Body>
      <Modal.Footer>
        <Button variant='light' onClick={handleCloseGenericMessage}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>,
    <Container>
      <Row xs={1} md={3}>
        <Col className='align-self-center'>
          <Card className={'pricingPlan' + (props.currentPlan === 'personal' ? ' currentPlan' : '')}>
            <Card.Body>
              <Card.Title>Personal</Card.Title>
              <Card.Text>
                <p className='price'>$5/mo</p>
                <li className='dotpoint'><span><img alt='checkmark' className='emoji' src='/check.png' /></span> Up to 10 vote options</li>
                <li className='dotpoint'><span><img alt='cross' className='emoji' src='/cross.png' /></span> Anonymize poll starter</li>
                <li className='dotpoint'><span><img alt='cross' className='emoji' src='/cross.png' /></span> Anonymize poll voters</li>
                <li className='dotpoint'><span><img alt='cross' className='emoji' src='/cross.png' /></span> Multiple user votes</li>
              </Card.Text>
              <Button
                onClick={changePlanConfirmation.bind(null, 'personal')}
                variant='primary'
                enabled={stripe}
                style={{ display: props.currentPlan === 'personal' ? 'none' : 'inline-block' }}>
                {props.authedView ? 'Switch To Plan' : 'Get Started'}</Button>
            </Card.Body>
          </Card>
        </Col>
        <Col className='align-self-center'>
          <Card className={'pricingPlan' + (props.currentPlan === 'business' || props.currentPlan === 'trial' ? ' currentPlan' : '')}>
            <Card.Body>
              <Card.Title>Business</Card.Title>
              <Card.Text>
                <p className='price'>$10/mo</p>
                <li className='dotpoint'><span><img alt='checkmark' className='emoji' src='/check.png' /></span> Up to 20 vote options</li>
                <li className='dotpoint'><span><img alt='checkmark' className='emoji' src='/check.png' /></span> Anonymize poll starter</li>
                <li className='dotpoint'><span><img alt='checkmark' className='emoji' src='/check.png' /></span> Anonymize poll voters</li>
                <li className='dotpoint'><span><img alt='checkmark' className='emoji' src='/check.png' /></span> Multiple user votes</li>
              </Card.Text>
              <Button
                onClick={changePlanConfirmation.bind(null, 'business')}
                variant='primary'
                enabled={stripe}
                style={{ display: props.currentPlan === 'business' ? 'none' : 'inline-block' }}>
                {props.authedView && props.currentPlan === 'trial' ? 'Lock In Plan' : 'Get Started'}
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col className='align-self-center'>
          <Card className={'pricingPlan' + (props.currentPlan === 'enterprise' ? ' currentPlan' : '')}>
            <Card.Body>
              <Card.Title>Enterprise</Card.Title>
              <Card.Text>
                <p className='price'>$20/mo</p>
                <li className='dotpoint'><span><img alt='checkmark' className='emoji' src='/check.png' /></span> Up to 45 vote options</li>
                <li className='dotpoint'><span><img alt='checkmark' className='emoji' src='/check.png' /></span> Anonymize poll starter</li>
                <li className='dotpoint'><span><img alt='checkmark' className='emoji' src='/check.png' /></span> Anonymize poll voters</li>
                <li className='dotpoint'><span><img alt='checkmark' className='emoji' src='/check.png' /></span> Multiple user votes</li>
              </Card.Text>
              <Button
                onClick={changePlanConfirmation.bind(null, 'enterprise')}
                variant='primary'
                enabled={stripe}
                style={{ display: props.currentPlan === 'enterprise' ? 'none' : 'inline-block' }}>
                {props.authedView ? 'Switch To Plan' : 'Get Started'}
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  ];
}
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import querystring from 'query-string';

import { Redirect } from 'react-router-dom';

import Pricing from '../components/Pricing.js';

export default props => {
  const query = querystring.parse(props.location.search);
  if (query.token) {
    localStorage.setItem('token', query.token);
    return <Redirect to={query.redirect || '/dashboard'} />
  }

  return (
    <div>
      <div className='banner'>
        <Navbar className='navBar' collapseOnSelect expand='lg' variant='dark'>
          <Navbar.Brand href='/'>Super Poll</Navbar.Brand>
          <Navbar.Toggle aria-controls='responsive-navbar-nav' />
          <Navbar.Collapse id='responsive-navbar-nav'>
            <Nav className='mr-auto' />
            <Nav>
              <Nav.Link className='navBarItems' href='/policy'>Policies</Nav.Link>
              <Nav.Link className='navBarItems' href='#pricing'>Pricing</Nav.Link>
              <Nav.Link href='/dashboard' className='navBarItems border'>Dashboard</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <div className='bannerBody'>
          <Container>
            <Row xs={1} md={2}>
              <Col xs={{ order: 2 }} md={{ order: 1 }} className='align-self-center title'>
                <h1>Easy team decisions, clean integrated interface, and anonymity</h1>
                <p></p>
                <a href={process.env.REACT_APP_SLACK_INSTALL_LINK}>
                  <img alt='Add to Slack to get started' height='40' width='139' src='https://platform.slack-edge.com/img/add_to_slack.png' srcset='https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x' />
                </a>
                <p></p>
                <p className='needhelp'>Need help? Send us an email over at <b>superpollxyz@gmail.com</b></p>
              </Col>
              <Col className='logo' xs={{ order: 1 }} md={{ order: 2 }}><img alt='splash' className='iconHero' src='./pollbot.svg' /></Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className='main'>
        <div className='features'>
          <div className='featureItem'>
            <Container>
              <Row xs={1} md={2}>
                <Col xs={{ order: 1 }} md={{ order: 2 }}><img alt='splash' className='hero' src='./example.png' /></Col>
                <Col xs={{ order: 1 }} md={{ order: 2 }} className='align-self-center'>
                  <p className='featureHeading'>Capture decisions and inspire input through a clean poll interface</p>
                  <p className='featureDescription'>Super Poll lets you create polls in-app with a clean prompt. Forget about fidgety long text based commands or opening another webpage.</p>
                </Col>
              </Row>
            </Container>
          </div>
          <div className='featureItem'>
            <Container>
              <Row xs={1} md={2}>
                <Col xs={{ order: 1 }} md={{ order: 2 }} className='align-self-center'><img alt='splash' src='./mask.png' /></Col>
                <Col xs={{ order: 2 }} md={{ order: 1 }} className='align-self-center'>
                  <p className='featureHeading'>Ask the right questions anonymously</p>
                  <p className='featureDescription'>Super Poll can create anonymous polls. This is suitable for all 'no stupid questions' moments all the way to important questions that need to be asked.
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
          <div className='featureItem'>
            <Container>
              <Row xs={1} md={2}>
                <Col xs={{ order: 1 }} md={{ order: 2 }} className='align-self-center'><img alt='splash' src='./vote.png' /></Col>
                <Col xs={{ order: 1 }} md={{ order: 2 }} className='align-self-center'>
                  <p className='featureHeading'>Promote honest feedback and thoughts</p>
                  <p className='featureDescription'>Create a hidden voters poll to allow users to vote without the fear of being judged for their opinion. If your question hinges on honest feedback, this is the one.</p>
                </Col>
              </Row>
            </Container>
          </div>
          <div className='featureItem'>
            <Container>
              <Row xs={1} md={2}>
                <Col xs={{ order: 1 }} md={{ order: 2 }} className='align-self-center'><img alt='splash' src='./multidecision.png' /></Col>
                <Col xs={{ order: 2 }} md={{ order: 1 }} className='align-self-center'>
                  <p className='featureHeading'>Snapshot multiple decisions</p>
                  <p className='featureDescription'>Not all decisions are black or white. Super Poll can be set up to allow each voter to cast votes for multiple items.</p>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      <div className='pricing' id='pricing'>
        <Pricing />
      </div>
    </div>
  );
}